import React, {useEffect, useRef, useState} from 'react'
import {MapContainer, TileLayer, Marker, Popup, Polyline} from 'react-leaflet'
import L from 'leaflet'
import {Link} from 'react-router-dom'
import {MAPBOX_URI} from '../../../config'
import 'leaflet/dist/leaflet.css'
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

type Props = any

export const FlightDetailMap = ({
  flight,
  airports,
  positionReports,
  positionReportsLoading,
  title,
  subTitle,
  center,
  className,
}: Props) => {
  const svgIcon = L.divIcon({
    html: `
            <svg
              width="50"
              height="50"
              viewBox="0 0 100 100"
              version="1.1"
              preserveAspectRatio="none"
              stroke="#1e87f0"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle r="5" cx="10" cy="10" fill="orange" />
            </svg>`,
    className: '',
    iconSize: [50, 50],
    iconAnchor: [5, 5],
  })

  const svgIcon2 = L.divIcon({
    html: `
            <svg
              width="50"
              height="50"
              viewBox="0 0 100 100"
              version="1.1"
              preserveAspectRatio="none"
              stroke="#1e87f0"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle r="5" cx="10" cy="10" fill="#fff" />
            </svg>`,
    className: '',
    iconSize: [50, 50],
    iconAnchor: [5, 5],
  })

  const mapRef = useRef<any>(null)

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView(center, 5)
    }
  }, [center])

  //   if (!positionReports?.length || !airports?.length) return <></>

  return (
    // <div className="uk-section" style={{margin: 0, passing: 0}}>
    <div
      className={clsx('card bgi-no-repeat bgi-size-cover', className)}
      style={{
        height: '80vh',
      }}
      //   style={{
      //     position: 'absolute',
      //     width: '100%',
      //     // height: calc(100% - 160px);
      //     top: 80,
      //     right: 0,
      //     bottom: 80,
      //     left: 0,
      //     zIndex: 500,
      //   }}
    >
      <MapContainer
        className='uk-section'
        style={{
          width: '100%',
          height: '100%',
          borderRadius: 'inherit',
          background: '#202224',
          backgroundImage: `url("${toAbsoluteUrl(`/media/misc/map-bg.jpg`)}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        center={center}
        zoom={5}
        ref={mapRef}
        scrollWheelZoom={true}
        zoomControl={false}
      >
        <TileLayer
          // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url={MAPBOX_URI}
        />
        {airports?.[0]?.latitude ? (
          airports.map((airport: any) => (
            <Marker
              key={'airport-' + airport.icao}
              icon={svgIcon}
              position={[airport.latitude, airport.longitude]}
            >
              <Popup>
                <p>
                  <strong>ICAO:</strong> {airport.icao}
                  <br></br>
                  <strong>Name:</strong> {airport.name}
                </p>
              </Popup>
            </Marker>
          ))
        ) : (
          <></>
        )}
        {positionReports?.length > 0 ? (
          <Polyline key={1} positions={positionReports} color={'#77c35c'} weight={2} />
        ) : (
          <></>
        )}
      </MapContainer>
      {!positionReportsLoading && !positionReports?.length && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            zIndex: 2,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0,0,0,.65)',
            // add a fade in animation
            animation: 'fadeIn 0.5s ease-in-out',
            pointerEvents: 'none',
            height: '100%',
            borderRadius: 'inherit',
            fontWeight: 'bold',
            fontSize: '1.5rem',
          }}
        >
          <p>No Position Data</p>
        </div>
      )}
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          zIndex: 2,
          // background: 'rgba(0,0,0,.45)',
          pointerEvents: 'none',
          height: '100%',
          borderRadius: 'inherit',
        }}
      >
        <div className='row'>
          {flight ? (
            <div className='col-xl-6' style={{pointerEvents: 'all'}}>
              <div
                className='card mx-16 my-20'
                style={{width: 350, maxWidth: 'calc(100vw - 30px)'}}
              >
                <div
                  style={{
                    width: '100%',
                    height: 200,
                    backgroundPosition: 'center, center, center',
                    backgroundImage: `url("${toAbsoluteUrl(
                      `/media/types/${flight.aircraft_type}.jpg`
                    )}")`,
                    backgroundSize: 'cover',
                    borderRadius: 'inherit',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: 200,
                      backgroundPosition: 'center, center, center',
                      background: `linear-gradient(to top, rgba(30, 30, 35, 1), rgba(30, 30, 35, .5))`,
                      backgroundSize: 'cover',
                    }}
                  ></div>
                </div>
                <div className='card-body pt-0' style={{overflowY: 'auto', maxHeight: '35vh'}}>
                  <div className='row mt-4'>
                    <div className='col-6'>
                      <h2>
                        {flight.schedule.callsign}
                        {flight.schedule.flight_number}
                      </h2>
                    </div>
                    <div className='col-6'>
                      <h5 className='mt-0 pt-0'>Aircraft Type:</h5>
                      <p>{flight.aircraft_type}</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <h5>Cruise Altitude:</h5>
                      <p>{flight.schedule.altitude}</p>
                    </div>
                    <div className='col-6'>
                      <h5>Notes:</h5>
                      <p>{flight.schedule.notes}</p>
                    </div>
                  </div>

                  <br></br>

                  <div>
                    <div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-4 px-4 me-6 mb-3'>
                        <p className='uk-margin-remove-bottom uk-padding-remove-bottom'>
                          {airports?.[0].icao} - {airports?.[0].name}
                        </p>
                        <p className='uk-text-italic uk-padding-remove-top uk-margin-remove-top'>
                          {new Date(flight.dep_time).toLocaleTimeString(undefined, {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div>
                    <div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-4 px-4 me-6 mb-3'>
                        <p className='uk-margin-remove-bottom uk-padding-remove-bottom'>
                          {airports?.[1].icao} - {airports?.[1].name}
                        </p>
                        <p className='uk-text-italic uk-padding-remove-top uk-margin-remove-top'>
                          {new Date(flight.arr_time).toLocaleTimeString(undefined, {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  {flight?.log ? (
                    <>
                      <br></br>
                      <div>
                        <div>
                          <h5>Log:</h5>
                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-4 px-4 me-6 mb-3'>
                            <div style={{overflowY: 'auto'}}>
                              {JSON.parse(flight.log).map((event: any) => {
                                const item = [
                                  [
                                    new Date(event.time).toLocaleString('en-US', {
                                      hour: 'numeric',
                                      minute: 'numeric',
                                      hour12: true,
                                    }),
                                  ],
                                  [event.event],
                                ]
                                return (
                                  <p
                                    key={'log-item-' + new Date(event.time).getTime()}
                                    className='uk-margin-small uk-text-small'
                                  >
                                    <span className='uk-text-italic'>{item[0]}: </span>
                                    {item[1]}
                                  </p>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
    // </div>
  )
}
