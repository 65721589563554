import clsx from 'clsx'
import {useMemo, useRef} from 'react'
import {MapContainer, Marker, Polyline, Popup, TileLayer} from 'react-leaflet'
import {MAPBOX_URI} from '../../../config'
import L from 'leaflet'
type Props = {
  className: string
  schedules: any[]
  airportsData: any
  setSelectedDeparture: any
  selectedDeparture: any
}

const svgIcon = L.divIcon({
  html: `
            <svg
              width="50"
              height="50"
              viewBox="0 0 100 100"
              version="1.1"
              preserveAspectRatio="none"
              stroke="#1e87f0"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle r="5" cx="10" cy="10" fill="orange" />
            </svg>`,
  className: '',
  iconSize: [50, 50],
  iconAnchor: [5, 5],
})
export const RouteMap: React.FC<Props> = ({
  className,
  schedules,
  airportsData,
  selectedDeparture,
  setSelectedDeparture,
}) => {
  const mapRef = useRef<any>(null)

  //const center = [10, 0]

  //   useEffect(() => {
  //     if (mapRef.current) {
  //       mapRef.current.setView(center, 5)
  //     }
  //   }, [center])

  const flightsWithCoordinates = useMemo(() => {
    if (schedules?.length && airportsData?.length) {
      const flightsWithCoordinates: any = []
      schedules.forEach((mapSchedule: any) => {
        const depAirport = airportsData.find(
          (airport: any) => airport.icao === mapSchedule.dep_icao
        )
        const arrAirport = airportsData.find(
          (airport: any) => airport.icao === mapSchedule.arr_icao
        )
        if (depAirport && arrAirport) {
          // if flight pair is already added, skip

          const find = flightsWithCoordinates.find(
            (schedule: any) =>
              (schedule.dep_icao === mapSchedule.dep_icao &&
                schedule.arr_icao === mapSchedule.arr_icao) ||
              (schedule.dep_icao === mapSchedule.arr_icao &&
                schedule.arr_icao === mapSchedule.dep_icao)
          )

          if (find) {
            return
          }

          flightsWithCoordinates.push({
            coordinates: [
              [depAirport.latitude, depAirport.longitude],
              [arrAirport.latitude, arrAirport.longitude],
            ],
          })
        }
      })
      return flightsWithCoordinates
    }
  }, [schedules, airportsData])

  return (
    <div
      className={clsx('card bgi-no-repeat bgi-size-cover px-0', className)}
      style={{
        height: '60vh',
      }}
    >
      <MapContainer
        className='uk-section'
        style={{
          width: '100%',
          height: '100%',
          borderRadius: 'inherit',
          background: '#202224',
        }}
        center={[30, -90]}
        zoom={5}
        ref={mapRef}
        scrollWheelZoom={true}
        zoomControl={false}
      >
        <TileLayer
          // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url={MAPBOX_URI}
        />
        {selectedDeparture &&
          flightsWithCoordinates?.length &&
          flightsWithCoordinates.map((route: any, id: number) => (
            <Polyline
              key={'flight-' + route.public_id}
              positions={route.coordinates}
              color={'#77c35c'}
              weight={1}
            />
          ))}
        {airportsData &&
          airportsData.map((airport: any) => (
            <Marker
              key={'airport-' + airport.icao}
              icon={svgIcon}
              position={[airport.latitude, airport.longitude]}
            >
              <Popup>
                <div>
                  <p>
                    <strong>ICAO:</strong> {airport.icao}
                    <br></br>
                    <strong>Name:</strong> {airport.name}
                  </p>
                  <button
                    className='btn btn-primary'
                    onClick={() => setSelectedDeparture(airport.icao)}
                  >
                    Show Destinations
                  </button>
                </div>
              </Popup>
            </Marker>
          ))}
      </MapContainer>
    </div>
    // </div>
  )
}
