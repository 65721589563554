/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import Skeleton from 'react-loading-skeleton'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  data: never[] | BaseData
  loading: boolean
}

type BaseData = {
  [key: string]: {
    // icao
    flights: number
    flight_time: number
    flights_30Days: number
    flight_time_30Days: number
  }
}

const MixedWidget11: React.FC<Props> = ({className, chartColor, chartHeight, data, loading}) => {
  const [topIcao, setTopIcao] = React.useState<string>('')
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    if (!data) {
      return
    }

    // flatten data icao into array
    const icaos = Object.keys(data)

    if (!icaos.length) {
      return
    }

    // get flights and flight times
    const flights = icaos.map((icao: string) => Math.round((data as BaseData)[icao].flights_30Days))

    // get flight times
    const flightTimes = icaos.map((icao) => Math.round((data as BaseData)[icao].flight_time_30Days))

    // Get icao string with most flights
    const highestFlights = icaos.reduce((prev, current) =>
      flights[icaos.indexOf(prev)] > flights[icaos.indexOf(current)] ? prev : current
    )

    setTopIcao(highestFlights)

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartColor, chartHeight, flights, flightTimes, icaos)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, loading, data])

  if (loading) {
    return (
      <div className={`card mb-5 card-rounded-bottom`}>
        {/* begin::Body */}
        <div className='card-body d-flex flex-column p-0'>
          <Skeleton
            count={1}
            height={270}
            style={{borderRadius: '0.75rem', opacity: '0.2'}}
            highlightColor={getCSSVariableValue('--bs-gray-100')}
            baseColor={getCSSVariableValue('--bs-gray-200')}
          />
        </div>
        {/* end::Body */}
      </div>
    )
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bold text-gray-800 d-block fs-3'>Crewbase Operations</span>

            <span className='text-gray-400 fw-semibold'>Previous 30 days</span>
          </div>

          <div>
            <div className={`fw-bold fs-3 text-${chartColor}`}>{topIcao}</div>
            <span className='text-gray-400 fw-semibold'>#1 in OPS</span>
          </div>
        </div>
        {/* end::Hidden */}

        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
        {/* end::Chart */}
      </div>
    </div>
  )
}

const chartOptions = (
  chartColor: string,
  chartHeight: string,
  totalFlights: number[],
  totalHoursArray: number[],
  crewbases: string[]
): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const secondaryColor = getCSSVariableValue('--kt-success')
  const baseColor = getCSSVariableValue('--kt-' + chartColor)

  return {
    series: [
      {
        name: 'Total Flights',
        data: totalFlights, //[50, 60, 70, 80, 60, 50, 70, 60],
      },
      {
        name: 'Total Hours',
        data: totalHoursArray, //[50, 60, 70, 80, 60, 50, 70, 60],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: crewbases, //['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      type: 'solid',
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return String(val)
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      padding: {
        top: 0,
        bottom: 20,
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export {MixedWidget11}
