export const CARRIER_ID = '7ce30940-9aa7-41b8-81b5-bf8830af863f'
export const SSO_URL = 'https://sso.rotate.group/'
export const CHECK_WX_API_KEY = '5f834ed10513dd66e5d3850fb5'
export const CARRIER_CALLSIGN = 'B6'
export const MAPBOX_URI =
  'https://api.mapbox.com/styles/v1/yancarlos4500/ckfkoamf41tsm19t6v1fro31b/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoieWFuY2FybG9zNDUwMCIsImEiOiJja2ZrbnQzdmExMDhnMzJwbTdlejNhdnJuIn0.aoHpGyZLaQRcp8SPYowuOQ'
export let API_URL =
  process.env.NODE_ENV === 'development'
    ? //'http://localhost:8787/'
      'https://api-v2.rotate.group/'
    : 'https://api-v2.rotate.group/'

export const socialLinks = [
  {
    title: 'Facebook',
    link: 'https://www.facebook.com/vblueofficial',
  },
  {
    title: 'Twitter',
    link: 'https://twitter.com/vblueofficial',
  },
  {
    title: 'Instagram',
    link: 'https://www.instagram.com/vblueofficial/',
  },
  {
    title: 'YouTube',
    link: 'https://www.youtube.com/@vblueofficial',
  }
]
